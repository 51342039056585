<style>
.btn_ing {
  width: 90px;
  height: 50px;
  text-align: center;
  font-size: 14px;
  line-height: 50px;
  border: 1px solid #103674;
}
.btn_end {
  width: 90px;
  height: 50px;
  text-align: center;
  font-size: 14px;
  line-height: 50px;
  border: 1px solid #103674;
}
.btn_selected {
  background: #103674;
  color: #fff;
}
.btn_event_ing {
  position: absolute;
  top: -16px;
  right: 0;
  color: #103674;
}
.btn_event_ing li {
  float: left;
}
.event_list li {
  float: left;
  margin: 5px 10px 25px 0px;
}
.event_list li:nth-child(3n + 0) {
  margin-right: 0px;
}
.event_section {
  width: 32%;
  margin: 2px;
  height: 320px;
}
.event_title {
  height: 40px;
  font-family: "Noto Sans KR", sans-serif;
  line-height: 40px;
  font-size: 16px;
  background: #103674;
  width: 100%;
  margin: 0 auto;
  color: #fff;
}
.event_date {
  bottom: 0;
  height: 40px;
  background: #f8f8f8;
  width: 300px;
  color: #000;
  text-align: center;
  line-height: 40px;
}
.event_list img {
  border-radius: 20px;
}
img {
  border-radius: 0 !important;
}
li {
  list-style: none;
}
</style>
<template>
  <div class="row">
    <!--
    <div class="col-md-12" style="height: 160px; margin-bottom: 40px">
      <img
        src="../assets/images/banner_sub_notice.png"
        style="border-bottom: 2px solid #103674"
      />
    </div>
    -->
    <div class="col-md-offset-3 col-md-6" style="min-height: 500px">
      <!--<h5 style="font-size:28px; color:#333;">Mypath <span style="font-weight:bold;">프로그램 소개</span></h5>-->

      <div class="cont_title" style="margin-left: 2px; position: relative">
        <h3 style="font-size: 30px; color: #103674">
          <img
            src="https://mypathkr.com/img/childu_logo.png"
            style="width: 120px; padding-bottom: 3px"
          />
          <span style="font-weight: 700">EVENT</span>
        </h3>
        <p
          style="
            padding: 10px 0px;
            font-size: 18px;
            font-weight: 500;
            color: #103674;
            text-align: center;
            border-bottom: 1px solid #ededed;
          "
        >
          차일드유에서 진행하고 있는 다양한 이벤트!
        </p>

        <ul class="btn_event_ing">
          <li
            class="btn_ing"
            :class="{ btn_selected: this.selMode == 'current' }"
            id="btn_ing"
            @click="
              this.selMode = 'current';
              loadEvents();
            "
          >
            진행중
          </li>
          <li
            class="btn_end"
            :class="{ btn_selected: this.selMode == 'end' }"
            id="btn_end"
            @click="
              this.selMode = 'end';
              loadEvents();
            "
          >
            마감
          </li>
        </ul>

        <ul
          class="event_list"
          id="prods"
          style="margin: 0 auto; padding-top: 10px"
        >
          <li class="event_section" v-for="event in events" :key="event.id">
            <!--<div class="event_title"> {{event.title}}</div>-->
            <div>
              <router-link :to="'/articles/' + event.id">
                <img :src="event.addi_data3" style="width: 100%; height: 100%"
              /></router-link>
              <div class="event_date" style="width: 100%">
                {{ event.addi_data1 }} ~ {{ event.addi_data2 }}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import { LMap, LGeoJson, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";
import axios from "axios";
export default {
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
    LMarker,
  },
  methods: {
    loadEvents() {
      axios
        .get(
          "https://api.mypathkr.com/articles?boards=2022_summer&mode=" +
            (this.selMode == "current" ? "non_exp" : "exp")
        )
        .then((response) => {
          this.events = response.data.result;
        });
    },
  },
  data: () => ({
    events: [],
    selMode: "current",
  }),
  async mounted() {
    this.loadEvents();
  },
};
</script>
